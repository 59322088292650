import Web3 from "web3";
import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import constractInterfaceMattereum from "../../assets/contractMattereum.json";
import constractInterfaceOrigin from "../../assets/contractOrigin.json";
import constractInterfaceProxy from "../../assets/contractProxy.json";
import ApiInterface from "../../services/api";
import ConfigKeys from "../../config/keys";

import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";

import "./mintButton.css";

export default function MintButton() {
  const keys = ConfigKeys.keys();
  const [emailMT, setEmailMT] = useState("");
  const [phoneMT, setPhoneMT] = useState("");
  const [nameMT, setNameMT] = useState("");
  const [privacyMT, setPrivacyMT] = useState(false);
  const [emailMessageMT, setEmailMessageMT] = useState("");
  const [whoContact, setWhoContact] = useState("artist");

  const [formError, setFormError] = useState();

  const [emailTT, setEmailTT] = useState("");
  const [hasApproved, setHasApproved] = useState(false);
  const [phoneTT, setPhoneTT] = useState("");
  const [nameTT, setNameTT] = useState("");
  const [privacyTT, setPrivacyTT] = useState(false);
  const [emailMessageTT, setEmailMessageTT] = useState("");

  const [account, setAccount] = useState(null);
  const [contractMattereum, setContractMattereum] = useState(null);
  const [contractOrigin, setContractOrigin] = useState(null);
  const [contractProxy, setContractProxy] = useState(null);

  const [originOwner, setOriginOwner] = useState(null);
  const [mattereumOwner, setMattereumOwner] = useState(null);

  //web3 modal

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: keys.infuraId, // required
      },
    },
    coinbasewallet: {
      package: CoinbaseWalletSDK, // Required
      options: {
        appName: "Eternal Passport", // Required
        infuraId: keys.infuraId, // Required
        rpc: "", // Optional if `infuraId` is provided; otherwise it's required
        chainId: 1, // Optional. It defaults to 1 if not provided
        darkMode: false, // Optional. Use dark theme, defaults to false
      },
    },
  };

  const web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    providerOptions,
    theme: "dark",
  });

  // STATES
  const [sellState, setSellState] = useState(null);

  const [errors, setErrors] = useState(null);
  const [message, setMessage] = useState(null);
  let [web3, setWeb3] = useState(null);
  useEffect(() => {
    checkAccount();
  }, [web3]);

  // invoke to check if account is already connected
  async function checkAccount() {
    const accounts = await web3.eth.getAccounts();
    let contract_mattereum = new web3.eth.Contract(constractInterfaceMattereum, keys.contract_address_matereum);
    let contract_proxy = new web3.eth.Contract(constractInterfaceProxy, keys.contract_address_proxy);

    let matereum_owner_address = await contract_mattereum.methods.ownerOf(keys.mattereum_token_id).call();

    setContractMattereum(contract_mattereum);
    setContractProxy(contract_proxy);
    setMattereumOwner(matereum_owner_address);

    if (!accounts[0]) {
      return;
    }

    setAccount(accounts[0]);
    if (accounts[0] === keys.shaun_address && accounts[0] === matereum_owner_address) {
      // console.log("saun");
      setSellState("SHAUN");
      return;
    }

    let contract_origin = new web3.eth.Contract(constractInterfaceOrigin, keys.contract_address_origin);
    let origin_owner_address = await contract_origin.methods.ownerOf(keys.origin_token_id).call();
    setContractOrigin(contract_origin);
    setOriginOwner(origin_owner_address);

    if (
      accounts[0].toLowerCase() === keys.auction_winner_address.toLowerCase() ||
      (accounts[0] === matereum_owner_address && accounts[0] !== keys.shaun_address) ||
      accounts[0] === origin_owner_address
    ) {
      let approved_address = await contract_origin.methods.getApproved(keys.origin_token_id).call();

      if (approved_address === keys.contract_address_proxy) {
        setHasApproved(true);
        setSellState("SWITCH");
      } else {
        setSellState("CONNECTED");
      }
    } else {
      setErrors("<b>ACCESS DENIED!</b><br>You do not hold the correct NFT to access this portal.");
    }
  }

  // invoke to connect to wallet account
  async function activate() {
    const provider = await web3Modal.connect();
    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log(info);
    });

    const web3 = new Web3(provider);
    setWeb3(web3);
  }

  async function estimateGas() {
    try {
      // console.log(account);
      let gas = await contractProxy.methods.claimEternalPassport().estimateGas({ from: account });
      return gas * 1.2;
    } catch (e) {
      console.log(e);
      if (e.code === -32000) {
        setErrors("Insufficent funds in wallet " + account);
      } else {
        setErrors("Cannot estimate GAS");
      }
      throw Error(e);
    }
  }

  async function estimateGasApprove() {
    try {
      //  console.log(account);
      let gas = await contractOrigin.methods
        .approve(keys.contract_address_proxy, keys.origin_token_id)
        .estimateGas({ from: account });
      return gas * 1.2;
    } catch (e) {
      console.log(e);
      if (e.code === -32000) {
        setErrors("Insufficent funds in wallet " + account);
      } else {
        setErrors("Cannot estimate GAS");
      }
      throw Error(e);
    }
  }

  async function estimateGasApproveShaun() {
    try {
      //console.log(account);
      let gas = await contractMattereum.methods
        .approve(keys.contract_address_proxy, keys.mattereum_token_id)
        .estimateGas({ from: account });
      return gas * 1.2;
    } catch (e) {
      console.log(e);
      if (e.code === -32000) {
        setErrors("Insufficent funds in wallet " + account);
      } else {
        setErrors("Cannot estimate GAS");
      }
      throw Error(e);
    }
  }

  async function switchNFT() {
    try {
      setSellState("SWITCHING");
      let gas = await estimateGas();
      contractProxy.methods
        .claimEternalPassport()
        .send({ from: account, gas: parseInt(gas) })
        .once("confirmation", function (confirmationNumber, receipt) {
          setSellState("DONE-SWITCH");
        })
        .on("error", function (error, receipt) {
          // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
          setErrors("Something went wrong");
        });
    } catch (e) {
      console.log(e);
      setErrors("Something went wrong");
      throw Error(e);
    }
  }

  async function approve() {
    try {
      setSellState("APPROVING");
      let gasApprove = await estimateGasApprove();
      contractOrigin.methods
        .approve(keys.contract_address_proxy, keys.origin_token_id)
        .send({ from: account, gas: parseInt(gasApprove) })
        .once("confirmation", function (confirmationNumber, receipt) {
          //console.log("ON CONFIRM", hasApproved, sellState);
          if (!hasApproved) {
            setHasApproved(true);
            setSellState("SWITCH");
          }
        })
        .on("error", function (error, receipt) {
          // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
          setErrors("Something went wrong");
        });
    } catch (e) {
      console.log(e);
      setErrors("Something went wrong");
      throw Error(e);
    }
  }

  async function approveProxy() {
    try {
      setSellState("APPROVING");
      let gasApprove = await estimateGasApproveShaun();
      contractMattereum.methods
        .approve(keys.contract_address_proxy, keys.mattereum_token_id)
        .send({ from: account, gas: parseInt(gasApprove) })
        .once("confirmation", function (confirmationNumber, receipt) {
          // console.log("ON CONFIRM", sellState);
          setMessage("<b>APPROVAL SENT!</b>");
          setSellState("DONE");
        })
        .on("error", function (error, receipt) {
          // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
          setErrors("Something went wrong");
        });
    } catch (e) {
      console.log(e);
      setErrors("Something went wrong");
      throw Error(e);
    }
  }

  const sendMessageTrumpet = async () => {
    let message =
      "name/alias:" +
      nameTT +
      "\nphone: " +
      phoneTT +
      "\nemail: " +
      emailTT +
      "\nmessage: " +
      emailMessageTT +
      "\n" +
      "\nAuction Winner: " +
      account +
      "\nTimestramp (UNIX): " +
      Date.now();

    if (verifyForm(emailTT, phoneTT, emailMessageTT, nameTT)) {
      setSellState("SENDING");
    } else {
      return;
    }

    try {
      let signature = await web3.eth.personal.sign(message, account);
      // console.log(message, signature);
      await ApiInterface.sendTrumpet(message, signature);
      setMessage("<b>MESSAGE SENT!</b><br>Management will contact you at the earliest convenience");
      setSellState("DONE");
    } catch (e) {
      console.error(e);
      setErrors("Something went wrong trying to send the message");
      throw Error(e);
    }
  };

  const sendMessageTrumpetMattereumOwner = async () => {
    let message =
      "name/alias:" +
      nameMT +
      "\nphone: " +
      phoneMT +
      "\nemail: " +
      emailMT +
      "\nmessage: " +
      emailMessageMT +
      "\n" +
      "\nETERNAL Passport Owner" +
      (account === keys.auction_winner_address ? " & Auction winner : " : ": ") +
      account +
      "\nTimestramp (UNIX): " +
      Date.now();

    if (verifyForm(emailMT, phoneMT, emailMessageMT, nameMT)) {
      setSellState("SENDING");
    } else {
      return;
    }

    try {
      let signature = await web3.eth.personal.sign(message, account);
      //console.log(message, signature);
      await ApiInterface.sendTrumpet(message, signature);
      setMessage("<b>MESSAGE SENT!</b><br>The relevant department will contact you to discuss your request.");
      setSellState("DONE");
    } catch (e) {
      console.error(e);
      setErrors("Something went wrong trying to send the message");
      throw Error(e);
    }
  };

  const sendMessageMattereum = async () => {
    let message =
      "name/alias:" +
      nameMT +
      "\nphone: " +
      phoneMT +
      "\nemail: " +
      emailMT +
      "\nmessage: " +
      emailMessageMT +
      "\n" +
      "\n" +
      "\nMettereum Owner: " +
      account +
      "\nTimestramp (UNIX): " +
      Date.now();

    if (verifyForm(emailMT, phoneMT, emailMessageMT, nameMT)) {
      setSellState("SENDING");
    } else {
      return;
    }

    try {
      let signature = await web3.eth.personal.sign(message, account);
      //console.log(message, signature);
      await ApiInterface.sendMattereum(message, signature);
      setMessage("<b>MESSAGE SENT!</b><br>The relevant department will contact you to discuss your request.");
      setSellState("DONE");
    } catch (e) {
      console.error(e);
      setErrors("Something went wrong trying to send the message");
      throw Error(e);
    }
  };

  const verifyForm = (email, phone, message, name) => {
    let messageErr = "";
    if (!email.match(/^\S+@\S+\.\S+$/)) {
      messageErr += "Email looks incorrect!<br/>";
    }

    if (!message) {
      messageErr += "Message is missing!<br/>";
    }

    if (!name) {
      messageErr += "Name/alias is missing!<br/>";
    }

    if (messageErr) {
      setFormError(messageErr);
      return false;
    } else {
      return true;
    }
  };

  let content = <></>;
  let origin_holder_component = (
    <>
      <Row className="justify-content-center text-center mt-5">
        <Col md="12">
          <h1 className="main-title mb-2">Welcome to the ETERNAL connection portal</h1>
          <p>
            Congratulations! Your journey starts here. As the holder of the ETERNAL NFT, you are authorized to claim the
            ETERNAL Passport which unlocks ownership of Timmy’s Trumpet, along with additional and ongoing benefits
            relating to future projects.
          </p>
          <p>
            <b>IMPORTANT:</b> Once authorized, you will be prompted to initiate the transfer of the ETERNAL Passport NFT
            into your wallet, which will simultaneously send the original ETERNAL token to a non-retrievable burn
            address.
          </p>
        </Col>
      </Row>
      <Row className="text-center mb-5">
        <Col>
          <button className="btn btn-custom" onClick={approve}>
            APPROVE PASSPORT
          </button>
        </Col>
        <p className="mt-3">*In order to claim the Eternal Passport you have to approve the token transfer</p>
      </Row>
    </>
  );

  let mattereum_holder_component = (
    <>
      <Row className="justify-content-center text-center mt-2 mb-5">
        <Col md="12">
          <h1 className="main-title">Welcome to the ETERNAL connection portal</h1>
          <p>
            Please make sure changes to your contact information are updated, and any messages sent include the details
            of your request. The information you provide is encrypted and will be sent securely to the party addressed.
          </p>
        </Col>
      </Row>
      <Row className="text-left">
        <Col>
          {formError ? (
            <div
              className="alert alert-danger-custom  text-center mb-3"
              role="alert"
              dangerouslySetInnerHTML={{ __html: formError }}
            ></div>
          ) : (
            <></>
          )}
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="mattereum"
              checked={whoContact === "mattereum"}
              onChange={(e) => setWhoContact(e.target.value)}
            />
            <label className="form-check-label">MATTEREUM (Regarding the Physical Asset)</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="artist"
              checked={whoContact === "artist"}
              onChange={(e) => setWhoContact(e.target.value)}
            />
            <label className="form-check-label">ARTIST MANAGEMENT (Rewards & Requests)</label>
          </div>
          <div className="form-group mt-2">
            <label>Name/Alias*</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setNameMT(e.target.value)}
              placeholder="Enter name/alias"
            />
          </div>
          <div className="form-group">
            <label>Email*</label>
            <input
              type="email"
              className="form-control"
              onChange={(e) => setEmailMT(e.target.value)}
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setPhoneMT(e.target.value)}
              placeholder="Enter phone number"
            />
          </div>
          <div className="form-group">
            <label>Message*</label>
            <textarea
              type="text"
              className="form-control"
              onChange={(e) => setEmailMessageMT(e.target.value)}
              placeholder="Enter a message"
            ></textarea>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              value={privacyMT}
              type="checkbox"
              onChange={(e) => setPrivacyMT(!privacyMT)}
            />
            <label className="form-check-label">
              You agree to be contacted on the details provided to discuss the enclosed enquiry.
            </label>
          </div>
          <div className="text-right">
            <button
              className="btn btn-custom mt-3"
              disabled={!privacyMT}
              onClick={whoContact === "mattereum" ? sendMessageMattereum : sendMessageTrumpetMattereumOwner}
            >
              Send message
            </button>
          </div>
        </Col>
      </Row>
    </>
  );

  let auction_winner_component = (
    <>
      <Row className="justify-content-center text-center mt-2 mb-5">
        <Col>
          <h1 className="main-title">CONGRATULATIONS!</h1>
          <p>
            Please authorize to be contacted by Artist Management below in order to discuss and arrange your Private Jet
            Festival Experience with Timmy Trumpet. The information you provide is encrypted and will be sent securely
            to the party addressed.
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          {formError ? (
            <div
              className="alert alert-danger-custom  text-center mb-3"
              role="alert"
              dangerouslySetInnerHTML={{ __html: formError }}
            ></div>
          ) : (
            <></>
          )}
          <div className="form-group">
            <label>Name/Alias*</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setNameTT(e.target.value)}
              placeholder="Enter name/alias"
            />
          </div>
          <div className="form-group">
            <label>Email*</label>
            <input
              type="email"
              className="form-control"
              onChange={(e) => setEmailTT(e.target.value)}
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setPhoneTT(e.target.value)}
              placeholder="Enter phone number"
            />
          </div>
          <div className="form-group">
            <label>Message*</label>
            <textarea
              type="text"
              className="form-control"
              onChange={(e) => setEmailMessageTT(e.target.value)}
              placeholder="Enter a message"
            ></textarea>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              value={!privacyTT}
              type="checkbox"
              onChange={(e) => setPrivacyTT(!privacyTT)}
            />
            <label className="form-check-label">
              You agree to be contacted on the details provided to discuss the enclosed enquiry.
            </label>
          </div>

          <div className="text-right">
            <button className="btn btn-custom mt-3" disabled={!privacyTT} onClick={sendMessageTrumpet}>
              Send message
            </button>
          </div>
        </Col>
      </Row>
    </>
  );

  switch (sellState) {
    case "CONNECTED":
      content = <></>;

      if (account === originOwner) {
        content = origin_holder_component;
      }

      if (account.toLowerCase() === keys.auction_winner_address.toLowerCase() && account !== originOwner) {
        content = auction_winner_component;
      }

      if (account === mattereumOwner) {
        content = mattereum_holder_component;
      }
      break;

    case "SHAUN":
      content = (
        <Row className="text-center mb-5">
          <Col>
            <button className="btn btn-custom mt-3" onClick={approveProxy}>
              APPROVE PASSPORT
            </button>
          </Col>
        </Row>
      );
      break;

    case "SWITCH":
      content = (
        <Row className="text-center mb-5">
          <Col>
            <h1 className="main-title">CLAIM NOW!</h1>
            <p>
              <b>IMPORTANT:</b> Once authorized, you will initiate the immediate transfer of the ETERNAL Passport NFT
              into your wallet, which will simultaneously send the original ETERNAL token to a non-retrievable burn
              address.
            </p>
            <button className="btn btn-custom mt-3" onClick={switchNFT}>
              CLAIM PASSPORT
            </button>
          </Col>
        </Row>
      );
      break;

    case "SWITCHING":
      content = (
        <div className="text-center">
          <div className="spinner-border text-light" role="status"></div>
          <br></br>
          <span className="sr-only">Claiming Eternal Passport...</span>
        </div>
      );
      break;

    case "APPROVING":
      content = (
        <div className="text-center">
          <div className="spinner-border text-light" role="status"></div>
          <br></br>
          <span className="sr-only">Waiting for approval...</span>
        </div>
      );
      break;

    case "SENDING":
      content = (
        <div className="text-center">
          <div className="spinner-border text-light" role="status"></div>
          <br></br>
          <span className="sr-only">Signing and sending message...</span>
        </div>
      );
      break;

    case "DONE":
      content = (
        <>
          <div
            className="alert alert-success-custom  text-center"
            role="alert"
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        </>
      );
      break;

    case "DONE-SWITCH":
      content = (
        <>
          <Row className="justify-content-center text-center mt-2 mb-5">
            <Col>
              <h1 className="main-title">TRANSFER COMPLETED!</h1>
              <p>
                Please check your wallet for the ETERNAL Passport NFT. You can connect your wallet to the portal anytime
                to update contact requests and make enquiries in relation to the asset and holder benefits.
              </p>
              <button
                className="btn btn-custom mt-3"
                onClick={() => {
                  window.location.reload();
                }}
              >
                REGISTER DETAILS & CLAIM AUCTION PRIZE
              </button>
            </Col>
          </Row>
        </>
      );
      break;

    default:
      content = (
        <div className="text-center mt-5">
          <a className="btn btn-lg btn-custom" onClick={activate}>
            Connect your wallet
          </a>
        </div>
      );
      break;
  }

  let error;

  if (errors) {
    error = (
      <div
        className="alert alert-danger-custom text-center"
        role="alert"
        dangerouslySetInnerHTML={{ __html: errors }}
      ></div>
    );
  }

  content = <>{content}</>;

  return (
    <div>
      <div>{error || content}</div>
    </div>
  );
}
