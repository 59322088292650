import React from "react";
import logo from "../..//assets/img/logo.svg";
import { Nav, Navbar } from "react-bootstrap";
import "./header.css";

export default function header() {
  return (
    <>
      <Navbar variant="dark" expand="sm">
        <img className="logo" src={logo} alt="logo"></img>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="align-items-center">
            <Nav.Link eventKey="1" href="https://eternalpassportnft.com">
              HOME
            </Nav.Link>
            <Nav.Link eventKey="2" href="https://eternalpassportnft.com/timeline/">
              TIMMY’S TRUMPET
            </Nav.Link>
            <Nav.Link eventKey="3" href="https://passport.mattereum.com/Trumpet.100122/">
              ASSET PASSPORT
            </Nav.Link>
            <Nav.Link eventKey="4" href="https://eternalpassportnft.com/#">
              ARTIST PORTAL
            </Nav.Link>
            <Nav.Link eventKey="5" href="https://eternalpassportnft.com/#">
              CONTACT
            </Nav.Link>
            <Nav.Link eventKey="6" href="#" className="btn btn-custom-2 pl-2">
              CLAIM PASSPORT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
