
import axios from 'axios';
import ConfigKeys from "../config/keys";
const keys = ConfigKeys.keys();
const api = keys.apiUrl
const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*"
};


const ApiInterface = {
    async sendTrumpet(message,signature) {

        try {
            let postData = {
                message,
                signature
            }
            // Default options are marked with *
            const response = await axios.post(api + "/send/trumpet", postData, { headers });

            return response.data
        } catch (e) {
            throw Error(e)
        }

    },

    
    async sendMattereum(message,signature) {

        try {
            let postData = {
                message,
                signature
            }
            // Default options are marked with *
            const response = await axios.post(api + "/send/mattereum", postData, { headers });

            return response.data
        } catch (e) {
            throw Error(e)
        }

    },

};

export default ApiInterface;