import "./App.css";
import MintButton from "./components/mintButton/mintButton";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import { Row, Col, Container } from "react-bootstrap";

function App() {
  return (
    <>
      <section className="overlay">
        <Container>
          <Header></Header>
          <Row className="justify-content-center pt-5 pb-5">
            <Col md="6" className="">
              <MintButton></MintButton>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
}

export default App;
