import { Component } from "react";

let development = {
  apiUrl: "http://localhost:8080/api",
  mattereum_token_id: 2,
  origin_token_id: 1,
  contract_address_matereum: "0x6070CcC90779F8F8F11cd121aD3E19dcBEd3668f",
  shaun_address: "0x24625aa92AB44c59Bb45a535db04a8774a388Eb7",
  contract_address_proxy: "0xEE2312CE022E36eF674B9e1319F3E5683707b3d8",
  contract_address_origin: "0x310910BcF0d8301a835D8831c44Fb616BaEae5F3",
  auction_winner_address: "0xDB5838C958C83836b654A4383a41314f422C2b4c",
};

let production = {
  apiUrl: "https://connect.eternalpassportnft.com/api",
  mattereum_token_id: 2,
  origin_token_id: 1,
  contract_address_matereum: "0x6070CcC90779F8F8F11cd121aD3E19dcBEd3668f",
  shaun_address: "0x24625aa92AB44c59Bb45a535db04a8774a388Eb7",
  contract_address_proxy: "0xEE2312CE022E36eF674B9e1319F3E5683707b3d8",
  contract_address_origin: "0x310910BcF0d8301a835D8831c44Fb616BaEae5F3",
  auction_winner_address: "0x169b0a0af452fe66ce28fdd4090652dd67444fb4",
};

class ConfigKeys extends Component {
  static keys() {
    var config;

    if (process.env.NODE_ENV === "production") {
      config = production;
    } else {
      config = development;
    }

    return config;
  }
}

export default ConfigKeys;
