import "./footer.css";
import { Row, Col, Container } from "react-bootstrap";
import tt_img from "../../assets/img/TT_logo.svg";

function Footer() {
  return (
    <>
      <footer>
        <Container>
          <Row className="justify-content-center align-items-center mb-3">
            <Col sm="4" md="2" className="text-center">
              <img className="logo-footer" src={tt_img} alt="Timmy Trumpet"></img>
            </Col>
          </Row>
          <Row>
            <Col className="text-center p-2 text-center">
              <small className="powered-by">Copyright © 2022 | Timmy Trumpet Blockchain Enterprises Pty Ltd </small>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
